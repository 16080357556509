import { useBreakpoints } from '@vueuse/core';
export const useDevice = () => {
  const breakpoints = {
    xs: 767,
    sm: 1024,
    md: 1230,
    lg: 1919,
  };

  return useBreakpoints(breakpoints);
};
