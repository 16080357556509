<template>
  <BottomModalWrapper
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.DirectionsModal">
      <div :class="$style.top">
        <h3 :class="$style.title">{{ t('choose a currency') }}</h3>

        <PagesHomeExchangeDirectionsFilter
          :active="filter"
          :items="filterItems"
          @change="filter = $event"
        />

        <VInput
          v-model="search"
          size="large"
          :placeholder="$t('search by name')"
          :prepend-inner-icon="IconsSearch"
        />
      </div>
      <div :class="$style.scroll">
        <ExchangeCalculatorDirectionsList
          :directions="filteredDirectionsBySearch"
          :active="data.active"
          @change="onDirectionChange"
        />
      </div>
    </div>
  </BottomModalWrapper>
</template>
<script setup lang="ts">
import { useFuse } from '@vueuse/integrations/useFuse';
import BottomModalWrapper from '~/components/common/BottomModalWrapper.vue';
import ExchangeCalculatorDirectionsList from '~/components/pages/home/exchange/ExchangeCalculatorDirectionsList.vue';
import IconsSearch from '~/components/Icons/IconsSearch.vue';
import VInput from '~/components/ui/Input/VInput.vue';

const props = defineProps({
  visible: Boolean,
  data: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['after-enter', 'after-leave', 'close']);

const bus = useEventBus(`calculator-direction:${props.data.direction}`);

function onDirectionChange(direction) {
  bus.emit(direction.id);
  emit('close');
}
const { t } = useI18n();
const filterItems = [
  { title: t('currencies'), id: 0 },
  { title: t('cryptocurrencies'), id: 1 },
];
const filter = ref(0);
const search = ref('');

const filteredDirectionsByFilter = computed(() => {
  if (filter.value === filterItems[0].id) {
    return props.data.directions.filter((item) => item.filter[0] !== 'c');
  }
  return props.data.directions.filter((item) => item.filter[0] === 'c');
});

const { results } = useFuse(search, filteredDirectionsByFilter, {
  fuseOptions: { keys: ['name', 'keywords'] },
  matchAllWhenSearchEmpty: true,
});
const filteredDirectionsBySearch = computed(() =>
  results.value.map((item) => item.item)
);
</script>

<style lang="scss" module>
.DirectionsModal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(90deg, #223540 0%, #1d2d36 100%);
  padding-right: 1rem;
}

.top {
  padding: 1.8rem 4rem 0 2.3rem;
}

.title {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: $base-100;
}

.scroll {
  height: 100%;
  overflow-y: scroll;
  padding: 1.7rem 2.8rem 1.7rem 2.3rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(#b5d841, 0.5);
    border-radius: 0.8rem;

    &:hover {
      background: rgba(#b5d841, 0.8);
    }
  }
}
</style>
