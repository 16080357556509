import { calculatorApi } from '~/utils/api/calculator';

interface Tech {
  time: number;
  on: boolean;
}

export default async function useTech() {
  const tech = ref<Tech>({ time: 0, on: false });

  async function getTech() {
    const { data, error } = await calculatorApi.fetchTech();
    if (error.value) {
      return;
    }
    if (!data.value) {
      return;
    }
    tech.value = data.value.technicalMode;
  }

  useIntervalFn(async () => {
    await getTech();
  }, 10000);

  await getTech();

  return {
    tech,
  };
}
