<template>
  <div :class="$style.ExchangeCalculatorDirectionsList">
    <div
      v-for="direction in items"
      :key="direction.name"
      type="button"
      :class="[$style.item, { [$style._active]: active === direction.name }]"
      @click="$emit('change', direction)"
    >
      <div :class="$style.inner">
        <div :class="$style.icon">
          <img
            :src="direction?.logo || direction.logoSimple"
            alt=""
            :class="$style.icon"
            width="32"
            height="32"
            loading="lazy"
            quality="80"
          />
        </div>
        <div :class="$style.content">
          <div :class="$style.title">
            {{
              Array.isArray(direction.currency)
                ? direction.currency[0].toUpperCase()
                : direction.currency.toUpperCase()
            }}
          </div>
          <div :class="$style.description">
            {{ direction.name }}
          </div>
        </div>
      </div>

      {{ direction.reserve }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { calculatorApi } from '~/utils/api/calculator';

const props = defineProps({
  directions: {
    type: Array,
    default: () => [],
  },
  active: {
    type: String,
    default: '',
  },
});

defineEmits(['change']);

const { data: reserves, pending } = await calculatorApi.getReserves();

const items = computed(() => {
  return props.directions.map((item: any) => {
    return {
      name: item.name,
      logo: item.logo.simple,
      reserve: reserves?.value?.find((reserve) => item.ids[0] === reserve.id)
        ?.reserve,
      currency: item.currency[0],
      id: item.id,
    };
  });
});
</script>

<style lang="scss" module>
.ExchangeCalculatorDirectionsList {
  position: relative;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  cursor: pointer;

  &:not(:first-of-type) {
    border-top: 1px solid rgba(#e0e0e0, 0.3);
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(#e0e0e0, 0.3);
  }

  &._active {
    .title {
      color: $brand-green;
    }
  }

  &:hover {
    .title {
      color: $brand-green;
    }
  }
}

.inner {
  display: flex;
  align-items: center;
}

.icon {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.4rem;
}

.title {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: $base-100;
  transition: color 0.3s ease;
}

.description {
  margin-top: 0.3rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #bdbdbd;
}
</style>
