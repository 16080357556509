<template>
  <div :class="$style.HomePartner">
    <div :class="$style.content">
      <h2 :class="$style.title">{{ $t('Become our partner') }}</h2>
      <div :class="$style.text">
        {{ $t('Participate in our affiliate program') }}
      </div>
      <UiVButton :class="$style.btn" @click="onButtonClick">
        {{ $t('read more') }}
      </UiVButton>
    </div>
    <img
      src="/images/home/partners/arrow.png"
      alt="arrow"
      :class="$style.icon"
    />
  </div>
</template>

<script setup>
const router = useRouter();
const localePath = useLocalePath();
function onButtonClick() {
  router.push(localePath('/partners'));
}
</script>

<style lang="scss" module>
.HomePartner {
  position: relative;
  padding: 11rem 7rem;
  margin-top: 9.5rem;
  background: linear-gradient(90deg, #223540 0%, #1d2d36 100%);
  border-radius: 30px;

  @include respond-to(md) {
    margin-top: 5rem;
  }

  @include respond-to(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: auto;
    margin-top: 85px;
    padding: 0;
    background: transparent;
  }
}

.content {
  max-width: 65.2rem;

  @include respond-to(sm) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}

.title {
  font-weight: 400;
  font-size: 3.6rem;
  line-height: 4.1rem;

  @include respond-to(sm) {
    text-align: center;
    font-size: 32px;
    line-height: 37px;
    color: $base-100;
  }
}

.text {
  margin-top: 1.4rem;
  color: #8b8b8b;

  @include respond-to(md) {
    max-width: 50%;
  }

  @include respond-to(sm) {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #bdbdbd;
  }

  @include respond-to(xs) {
    max-width: 90%;
  }
}

.icon {
  position: absolute;
  top: -5.7rem;
  right: 2.2rem;
  max-width: 54.3rem;
  max-height: 44.8rem;

  @include respond-to(sm) {
    display: none;
    top: unset;
    right: unset;
  }
}

.btn {
  margin-top: 2.3rem;

  @include respond-to(sm) {
    margin-top: 18px;
  }
}
</style>
