<template>
  <BaseModal :class="$style.RestoreModal" login>
    <div :class="$style.title">
      {{ $t('password recovery') }}
    </div>
    <p v-if="errors?.nonFieldErrors" :class="$style.error">
      {{ errors.nonFieldErrors }}
    </p>
    <form v-if="!success" :class="$style.form" @submit.prevent="onSubmit">
      <div :class="$style.input">
        <InputPassword
          v-model="password"
          :placeholder="$t('new password')"
          type="password"
          check
          name="password"
          :error="errors?.password"
          @input="errors.password = ''"
        />
      </div>
      <div :class="$style.input">
        <InputPassword
          v-model="passwordAgain"
          :placeholder="$t('repeat password')"
          type="password"
          check
          :error="errors?.passwordAgain"
          name="passwordAgain"
          @input="errors.passwordAgain = ''"
        />
      </div>
      <div :class="$style.button">
        <UiVButton
          size="large"
          color="primary"
          responsive
          type="submit"
          spinner
          :loading="loading"
        >
          {{ $t('save') }}
        </UiVButton>
      </div>
    </form>
    <div v-else :class="$style.success">
      {{ $t('password changed successfully') }}
    </div>
  </BaseModal>
</template>

<script setup>
import BaseModal from '~/components/common/BaseModal.vue';
import InputPassword from '~/components/common/InputPassword.vue';
import { userApi } from '~/utils/api/user';
import { useFormErrors } from '~/composables/useFormErrors';

const password = ref('');
const passwordAgain = ref('');
const loading = ref(false);
const success = ref(false);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const { errors, setErrors } = useFormErrors();
const emit = defineEmits(['close']);

async function onSubmit() {
  try {
    loading.value = true;
    const response = await userApi.restorePassword(props.data.token, {
      password: password.value,
      passwordAgain: passwordAgain.value,
    });
    if (response.result === 'ok') {
      password.value = '';
      passwordAgain.value = '';
      success.value = true;
    }
    loading.value = false;
  } catch (error) {
    console.log(error.data);
    error?.data
      ? setErrors(error.data)
      : console.warn(
          '[ExchangeRequisites/handleSubmit] POST request failed: ',
          error
        );
  } finally {
    loading.value = false;
  }
}

onUnmounted(() => {
  const router = useRouter();
  router.replace('/');
});
</script>

<style lang="scss" module>
.RestoreModal {
  //
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: $base-100;
}

.success {
  margin-top: 3rem;
  color: $brand-green;
}

.form {
  margin-top: 22px;

  @include respond-to(xs) {
    margin-top: 25px;
  }
}

.input {
  &:not(:last-child) {
    margin-bottom: 13px;

    @include respond-to(xs) {
      margin-bottom: 11px;
    }
  }
}

.error {
  font-size: 14px;
  color: $error-color;
}

.success {
  margin-top: 3rem;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  @include respond-to(xs) {
    color: $base-100;
  }
}

.error {
  color: $error-color;
}
</style>
