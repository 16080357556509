<template>
  <div :class="$style.HomeExchangeBlock">
    <transition name="fade-fast">
      <HomeExchangeTechMode v-if="isTechMode" :time="time" />
    </transition>
    <div :class="$style.top">
      <HomeExchangeCalculator :directions="directions" />
      <UiVButton icon @click="$router.push(localePath('/claim'))">
        <template #icon>
          <IconsExchange :class="$style.icon" />
        </template>
        {{ btnText }}
      </UiVButton>
    </div>
    <div :class="$style.bottom">
      <ExchangeCourse :class="$style.course" />
      <div :class="$style.right">
        <IconsHelp :class="$style.help" />
        <div :class="$style.info" @click="openInstructionModal">
          {{ howToMakeAnExchange }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import HomeExchangeCalculator from '~/components/pages/home/exchange/HomeExchangeCalculator.vue';
import ExchangeCourse from '~/components/pages/home/exchange/ExchangeCourse.vue';
import InstructionModal from '~/components/common/InstructionModal.vue';
import HomeExchangeTechMode from '~/components/pages/home/exchange/HomeExchangeTechMode.vue';

const localePath = useLocalePath();

defineProps({
  directions: {
    type: Array,
    default: () => [],
  },
  isTechMode: {
    type: Boolean,
    default: false,
  },
  time: {
    type: Number,
    default: 0,
  },
});

const openInstructionModal = () => {
  const { $modal } = useNuxtApp();
  $modal.open(markRaw(InstructionModal));
};

const { t } = useI18n({
  useScope: 'local',
});

const btnText = computed(() => t('Обменять'));
const howToMakeAnExchange = computed(() => t('Как совершить обмен'));
</script>

<i18n lang="json">
{
  "ru": {
    "Обменять": "Обменять",
    "Как совершить обмен": "Как совершить обмен"
  },
  "en": {
    "Обменять": "Exchange",
    "Как совершить обмен": "How to make an exchange"
  }
}
</i18n>

<style lang="scss" module>
.HomeExchangeBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 18rem;
  margin-top: 5rem;
  padding: 1.8rem 3.2rem;
  background-color: rgba(#1d2532, 0.5);
  border-radius: $border-radius-default;

  @include respond-to(sm) {
    min-height: 440px;
    margin-top: 3rem;
    padding: 1.8rem 2.4rem;
  }

  @include respond-to(xs) {
    min-height: 400px;
    padding: 34px 18px;
    background-color: rgba(#11151d, 0.5);
  }
}

.top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 2rem;

  @include respond-to(sm) {
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }
}

.swap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: $brand-green;
  border-radius: 50%;
  font-size: 2.8rem;
  color: $base-0;
  cursor: pointer;

  @include respond-to(sm) {
    margin: 0 auto 0 auto;
  }

  @include respond-to(xs) {
    width: 42px;
    height: 42px;
    margin: 0 0 0 auto;
    font-size: 18px;
    color: #1d2d36;
  }
}

.iconSwap {
  width: 2.8rem;
  height: 2.8rem;
  color: $base-0;

  @include respond-to(xs) {
    width: 18px;
    height: 18px;
  }
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  color: $body-bg;
}

.count {
  height: 24px;
  width: 24px;

  span {
    font-size: 12px;
    line-height: 14px;
  }
}

.course {
  @include respond-to(xs) {
    position: absolute;
    top: 27%;
    left: 39px;
    width: 75%;
  }
}

.help {
  width: 1.8rem;
  height: 1.8rem;
  color: $brand-green;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond-to(xs) {
    justify-content: center;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include respond-to(xs) {
    position: absolute;
    top: 27%;
    left: 39px;
    width: 75%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 5px;
    color: white;
  }
}

.leftTitle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.right {
  display: flex;
  align-items: center;
}

.info {
  width: 13.5rem;
  margin-left: 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-decoration-line: underline;
  cursor: pointer;
  transition: color $base-transition;

  &:hover {
    color: $brand-green;
  }
}

.input {
  position: relative;
}

.directions {
  @include respond-to(sm) {
    display: none;
  }
}
</style>
