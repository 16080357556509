<template>
    <div :class="$style.DirectionsFilter">
        <div v-for="item in items" :key="item.id" :class="[$style.item, {[$style._active]: active === item[valueName]}]" @click="onItemClick(item)">
            {{ item[titleName] }}
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    active: {
        type: [Number, String],
        required: true,
    },
    items: {
        type: Array,
        default: () => ['Валюты', 'Криптовалюты'],
    },
    titleName: {
        type: String,
        default: 'title',
    },
    valueName: {
        type: String,
        default: 'id',
    },
});
const emit = defineEmits(['change']);

function onItemClick(item) {
    if (item.id !== props.active) {
        emit('change', item[props.valueName]);
    }
}

// const active = ref('Валюты');
// const items = ref(['Валюты', 'Криптовалюты']);
</script>

<style lang="scss" module>
.DirectionsFilter {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .7rem;
    padding: .3rem;
    background: rgba(#EAEAEA, .4);
    border-radius: 3rem;
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2rem;
    cursor: pointer;
    border-radius: 2.9rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: $base-100;
    transition: color .3s ease;

    &._active {
        color: #1D2D36;
        background: linear-gradient(106.3deg, #B5D841 29.93%, #D7FF53 73.93%);
    }
}
</style>
