<template>
  <div :class="$style.ExchangeCalculatorDirections">
    <div :class="$style.top">
      <h3 :class="$style.title">{{ t('choose a currency') }}</h3>
      <PagesHomeExchangeDirectionsFilter
        :active="filter"
        :items="filterItems"
        @change="filter = $event"
      />

      <VInput
        ref="inputRef"
        v-model="search"
        size="large"
        :placeholder="$t('search by name')"
        :prepend-inner-icon="IconsSearch"
      />
    </div>

    <div :class="$style.scroll">
      <ExchangeCalculatorDirectionsList
        :directions="filteredDirectionsBySearch"
        :active="active"
        @change="$emit('change', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFuse } from '@vueuse/integrations/useFuse';
import ExchangeCalculatorDirectionsList from '~/components/pages/home/exchange/ExchangeCalculatorDirectionsList.vue';
import IconsSearch from '~/components/Icons/IconsSearch.vue';
import VInput from '~/components/ui/Input/VInput.vue';

const inputRef = ref<HTMLElement | null>(null);

const props = defineProps({
  directions: {
    type: Array,
    default: () => [],
  },
  active: {
    type: String,
    default: '',
  },
});

defineEmits(['change']);

const { t } = useI18n();

const filterItems = [
  { title: t('currencies'), id: 0 },
  { title: t('cryptocurrencies'), id: 1 },
];
const filter = ref(0);
const search = ref('');

const filteredDirectionsByFilter = computed(() => {
  if (filter.value === filterItems[0].id) {
    return props.directions.filter((item) => item.filter[0] !== 'c');
  }
  return props.directions.filter((item) => item.filter[0] === 'c');
});

const { results } = useFuse(search, filteredDirectionsByFilter, {
  fuseOptions: { keys: ['name', 'keywords'] },
  matchAllWhenSearchEmpty: true,
});
const filteredDirectionsBySearch = computed(() =>
  results.value.map((item) => item.item)
);

function focusInput() {
  if (inputRef.value) {
    inputRef.value.input.focus();
  }
}

onMounted(() => {
  focusInput();
});
</script>

<style lang="scss" module>
.ExchangeCalculatorDirections {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  padding-right: 0.7rem;
  background: linear-gradient(90deg, #223540 0%, #1d2d36 100%);
  border-radius: 15px;
  border: 1px solid var(--Stroke, #2d4452);
}

.top {
  padding: 1.8rem 4rem 0 2.3rem;
}

.title {
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: $base-100;
}

.scroll {
  height: 100%;
  overflow-y: scroll;
  padding: 1.7rem 2.8rem 1.7rem 2.3rem;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(#b5d841, 0.5);
    border-radius: 0.8rem;

    &:hover {
      background: rgba(#b5d841, 0.8);
    }
  }
}
</style>
