<template>
  <button
    type="button"
    :class="[$style.HomeExchangeSwap, { [$style._disabled]: !isSwapAvailable }]"
    @click="swap"
  >
    <IconsSwap :class="$style.icon" />
  </button>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useCalculatorStore } from '~/stores/useCalculatorStore';
import { calculatorApi } from '~/utils/api/calculator';
import useDirectionsCalculator from '~/composables/calculator/useDirectionsCalculator';

const calculatorStore = useCalculatorStore();

const { fromCurrencyId, toCurrencyId } = storeToRefs(calculatorStore);
const isSwapAvailable = ref(false);

watch(
  [fromCurrencyId, toCurrencyId],
  async () => {
    await checkSwap();
  },
  { immediate: true }
);

async function checkSwap() {
  try {
    await calculatorApi.pair(toCurrencyId.value, fromCurrencyId.value);
    isSwapAvailable.value = true;
  } catch (e) {
    isSwapAvailable.value = false;
  }
}

function swap() {
  if (!isSwapAvailable.value) return;
  useDirectionsCalculator().onCurrenciesChange(
    toCurrencyId.value,
    fromCurrencyId.value
  );
}
</script>

<style lang="scss" module>
.HomeExchangeSwap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  background-color: $brand-green;
  border-radius: 50%;
  font-size: 2.8rem;
  color: $base-0;
  cursor: pointer;
  flex-shrink: 0;

  @include respond-to(sm) {
    margin: 0 auto 0 auto;
  }

  @include respond-to(xs) {
    width: 42px;
    height: 42px;
    margin: 0 0 0 auto;
    font-size: 18px;
    color: #1d2d36;
  }

  transition: background-color 0.3s ease-in-out;
  &._disabled {
    background-color: $green-dark;
    cursor: default;
  }
}

.icon {
  width: 2.8rem;
  height: 2.8rem;
  color: $base-0;

  @include respond-to(xs) {
    width: 18px;
    height: 18px;
  }
}
</style>
