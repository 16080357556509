import { storeToRefs } from 'pinia';
import { useCalculatorStore } from '~/stores/useCalculatorStore';

export default function useValues() {
  const store = useCalculatorStore();
  const { setFromValue } = store;
  const { fromValue, course, pairData } = storeToRefs(store);

  const round = computed(() => {
    return {
      from: pairData.value.from.roundCalculator,
      to: pairData.value.to.roundCalculator,
    };
  });

  const toValue = computed(() => {
    if (!fromValue.value || !course.value) {
      return null;
    }
    return fromValue.value * course.value;
  });

  const limits = computed(() => {
    const from = pairData.value.from;
    const to = pairData.value.to;
    return {
      from: {
        min: from.min ? from.min : to.min / course.value!,
        max: from.max,
      },
      to: {
        min: to.min ? to.min : from.min * course.value!,
        max: to.max,
      },
    };
  });

  const notifications = computed(() => {
    if (!pairData) return null;
    return {
      from: pairData.value.from.notify,
      to: pairData.value.to.notify,
    };
  });
  const handleInputFrom = (value: number) => {
    setFromValue(value);
  };

  const handleInputTo = (value: number) => {
    setFromValue(value / course.value);
  };

  const handleChangeFrom = (value: number) => {
    if (value < limits.value.from.min) {
      setFromValue(limits.value.from.min);
    } else if (value > limits.value.from.max) {
      setFromValue(limits.value.from.max);
    }
  };

  const handleChangeTo = (value: number) => {
    handleChangeFrom(value / course.value);
  };
  return {
    fromValue,
    round,
    toValue,
    handleInputFrom,
    handleInputTo,
    limits,
    handleChangeFrom,
    handleChangeTo,
    notifications,
  };
}
