<template>
  <div :class="$style.HomeExchangeTechMode">
    <span :class="$style.text">
      {{ t('Ведутся технические работы') }}.<br />
      {{ t('Возвращайтесь через') }}
      <span class="brand">{{ timeLeft }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { formatTime } from '~/utils/date';

const { t } = useI18n({
  useScope: 'local',
});

const props = defineProps({
  time: {
    type: Number,
    default: 0,
  },
});

const now = useNow();
const endTime = ref<Date | null>(null);

function initTimer(seconds: number) {
  endTime.value = new Date(now.value.getTime() + seconds * 1000);
}

const timeLeft = computed(() => {
  if (!endTime.value) return 0;
  const secondsLeft = Math.floor(
    (endTime.value.getTime() - now.value.getTime()) / 1000
  );
  if (secondsLeft <= 0) return 0;
  return formatTime(secondsLeft);
});

initTimer(props.time);
</script>

<i18n lang="json">
{
  "ru": {
    "Ведутся технические работы": "Ведутся технические работы",
    "Возвращайтесь через": "Возвращайтесь через"
  },
  "en": {
    "Ведутся технические работы": "Technical work is underway",
    "Возвращайтесь через": "Come back in"
  }
}
</i18n>

<style lang="scss" module>
.HomeExchangeTechMode {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  border-radius: $border-radius-default;
}

.text {
  width: 100%;
  text-align: center;
  font-size: 3.6rem;
  font-weight: 450;
  line-height: 1;
  color: $base-100;

  @include respond-to(xs) {
    font-size: 22px;
  }
}
</style>
