<template>
  <div :class="$style.CalculatorNetworkLoad">
    <div
      :class="$style.circle"
      :style="{ backgroundColor: `${content.color}` }"
    />
    <div :class="$style.title">
      {{ t('Загруженность сети BTC') }} - {{ content.congestion }}
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n({
  useScope: 'local',
});

const { load } = defineProps({
  load: {
    type: Number,
    default: 0,
  },
});

const content = computed(() => {
  switch (load as any) {
    case 1:
      return {
        congestion: t('низкая'),
        color: '#25BC46',
      };

    case 2:
      return {
        congestion: t('средняя'),
        color: '#B0BC25',
      };
    case 3:
      return {
        congestion: t('средняя'),
        color: '#B0BC25',
      };
    case 4:
      return {
        congestion: 'высокая',
        color: '#BC3725',
      };
    default:
      return {
        congestion: '',
        color: '#BC3725',
      };
  }
});
</script>

<i18n lang="json">
{
  "ru": {
    "Загруженность сети BTC": "Загруженность сети BTC",
    "низкая": "низкая",
    "средняя": "средняя",
    "высокая": "высокая"
  },
  "en": {
    "Загруженность сети BTC": "BTC network load",
    "низкая": "low",
    "средняя": "average",
    "высокая": "high"
  }
}
</i18n>

<style lang="scss" module>
.CalculatorNetworkLoad {
  display: flex;
  align-items: center;
  font-size: 16px;

  @include respond-to(sm) {
    justify-content: center;
  }
}

.title {
  margin-left: 10px;

  @include respond-to(xs) {
    font-size: 12px;
  }
}

.circle {
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
}
</style>
