<template>
  <div :class="$style.ExchangeCalculatorInput">
    <span :class="$style.caption">
      {{ title }}
    </span>
    <div :class="$style.content">
      <VInputThousands
        :value="value"
        :decimal-count="round"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
      />
      <ExchangeCalculatorInputTrigger
        :active="isListOpen"
        :class="$style.trigger"
        :logo-src="currency?.logo?.simple"
        :name="currency?.currency?.[0]"
        @click="openList"
      />
    </div>
    <transition name="fade">
      <ExchangeCalculatorDirections
        v-if="isListOpen"
        ref="directionsRef"
        :directions="directions"
        :active="currency.name"
        :class="$style.list"
        @change="onDirectionChange"
      />
    </transition>
    <slot name="info" />
  </div>
</template>

<script setup lang="ts">
import VInputThousands from '~/components/ui/VInputThousands.vue';
import ExchangeCalculatorInputTrigger from '~/components/pages/home/exchange/ExchangeCalculatorInputTrigger.vue';
import ExchangeCalculatorDirections from '~/components/pages/home/exchange/ExchangeCalculatorDirections.vue';
import { useDevice } from '~/composables/useDevice';
import DirectionsListModal from '~/components/common/modals/DirectionsListModal.vue';

const props = defineProps({
  direction: {
    type: String,
    default: '',
  },
  value: {
    type: [Number, String],
    default: 0,
  },
  currency: {
    type: Object,
    default: () => ({}),
  },
  directions: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  round: {
    type: Number,
    default: 4,
  },
});

const isListOpen = ref(false);
const isOpen = refDebounced(isListOpen, 100);
const directionsRef = ref(null);

onClickOutside(directionsRef, () => {
  closeList();
});

const device = useDevice();

const openList = () => {
  if (isOpen.value) {
    return;
  }
  if (device.isSmaller('sm')) {
    openListModal();
  } else {
    isListOpen.value = true;
  }
};

const closeList = () => {
  isListOpen.value = false;
};

const openListModal = () => {
  const { $modal } = useNuxtApp();
  $modal.open(markRaw(DirectionsListModal), {
    directions: props.directions,
    active: props.currency.name,
    direction: props.direction,
  });
};

const bus = useEventBus(`calculator-direction:${props.direction}`);
const onDirectionChange = (direction) => {
  closeList();
  bus.emit(direction.id);
};

defineEmits(['input', 'change']);
</script>

<style lang="scss" module>
.ExchangeCalculatorInput {
  position: relative;
  width: 100%;
}

.caption {
  margin-left: 2.4rem;
  font-size: 12px;

  @include respond-to(xs) {
    display: none;
  }
}

.content {
  position: relative;
}

.trigger {
  position: absolute;
  top: 0;
  right: 0;
}

.list {
  z-index: 3;
  position: absolute;
  top: 110%;
}
</style>
