export function getDirectionsList(directions, ids: number[]) {
  return directions
    .filter((direction) => ids.some((id) => direction.ids.includes(id)))
    .map((direction) => ({
      ...direction,
      id: ids.find((id) => direction.ids.includes(id)),
    }));
}

export function getDirectionItem(directions, id: number) {
  const direction = directions.find((dir) => dir.ids.includes(id));
  return direction ? { id, ...direction } : null;
}
