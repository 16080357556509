<template>
  <BaseModal
    :class="$style.NotificationModal"
    login
    :visible="visible"
    @after-enter="$emit('after-enter')"
    @after-leave="$emit('after-leave')"
    @close="$emit('close')"
  >
    <div :class="$style.title">
      {{ $t('Уведомление') }}
    </div>
    <div :class="$style.column">
      <div
        v-for="{ message, name } in data.notification"
        :key="name"
        :class="$style.text"
      >
        <strong class="brand">{{ name }}:</strong>
        <p class="common-text" v-html="message"></p>
      </div>
    </div>
    <div :class="$style.button">
      <UiVButton size="large" color="primary" responsive @click="emit('close')">
        {{ $t('well') }}
      </UiVButton>
    </div>
  </BaseModal>
</template>

<script setup>
import BaseModal from './BaseModal.vue';

const emit = defineEmits(['close', 'after-enter', 'after-leave']);

defineProps({
  data: {
    type: Object,
    required: true,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" module>
.NotificationModal {
  //
}

.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 1rem;
  color: $base-100;
}

.success {
  font-weight: 400;
  font-size: 16px;
  line-height: 136.99%;
  /* or 22px */

  text-align: center;

  /* dsgn_text */

  color: #a7a7a7;
  margin-top: 19px;
}

.input {
  &:not(:last-child) {
    margin-bottom: 13px;

    @include respond-to(xs) {
      margin-bottom: 11px;
    }
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}

.check {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #a7a7a7;
}

.forgot {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: $base-100;
  }
}

.button {
  margin-top: 25px;
}

.text {
  text-align: center;
  white-space: break-spaces;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
